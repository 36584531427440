<template>
  <div class="ReportsView">
    <div class="header">
      <TransitionSlide>
        <div class="text">
          <h1>{{ $t('TITLE.REPORT.YOU') }}</h1>
        </div>
      </TransitionSlide>
    </div>

    <div class="content window-fit">
      <div class="filter">
        {{ $t('FILTER.PROGRAMS') }}
        <BaseSelect
          v-model="search"
          v-bind="questionAttributes"
        />
        <!--  <div v-if="filterProgram">
          {{ filterReport }}
        </div> -->
      </div>

      <ContainerReport
        :items="filterReport"
        class="report"
      />
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import TransitionSlide from '@/transitions/TransitionSlide';
import ContainerReport from '@/components/reports-user/ContainerReport';

export default {
  components: {
    TransitionSlide,
    ContainerReport,
  },

  data() {
    return {
      itemsSearch:
        {
          question: 'PROGRAM',
          choices: [1, 2, 3, 4, 5],
        },

      search: '',
    };
  },

  computed: {
    ...get('reportModule', [
      'getReportsuser',
      'getFilterProgram',
    ]),

    reportUser() {
      return this.getReportsuser();
    },

    filterProgram() {
      return this.getFilterProgram(this.search);
    },

    questionAttributes() {
      return Object.assign({}, this.itemsSearch, { name: 'PROGRAM', disabled: this.isDisabled });
    },

    filterReport() {
      if (this.search) {
        return this.filterProgram;
      }
      return this.reportUser;
    },
  },

  async created() {
    await this.$store.dispatch('reportModule/fetchReportUser');
  },
};
</script>

<style lang="scss" scoped>
.ReportsView {
  .header {
    padding-top: $theHeaderSpacing;
    background-image: url('~@/assets/images/f_home.jpg');
    background-size: cover;
    height: 220px;
    .text {
      margin: 3rem auto 3rem;
      max-width: 1200px;
      padding: 0 2rem 2rem;
      h1 {
        color: white;
        text-transform: uppercase;
        font-size: $font-size-3xl;
        font-weight: bold;
      }
    }
  }
  .content {
    max-width: 1200px;
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;

    .report {
      width: 100%;
    }

    .filter{
      width: 100%;
      margin-bottom: $spacing-m;
    }
  }
}
</style>
