var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ReportsView" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("TransitionSlide", [
          _c("div", { staticClass: "text" }, [
            _c("h1", [_vm._v(_vm._s(_vm.$t("TITLE.REPORT.YOU")))])
          ])
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content window-fit" },
      [
        _c(
          "div",
          { staticClass: "filter" },
          [
            _vm._v(" " + _vm._s(_vm.$t("FILTER.PROGRAMS")) + " "),
            _c(
              "BaseSelect",
              _vm._b(
                {
                  model: {
                    value: _vm.search,
                    callback: function($$v) {
                      _vm.search = $$v
                    },
                    expression: "search"
                  }
                },
                "BaseSelect",
                _vm.questionAttributes,
                false
              )
            )
          ],
          1
        ),
        _c("ContainerReport", {
          staticClass: "report",
          attrs: { items: _vm.filterReport }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }