var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ContainerReport" }, [
    _c(
      "div",
      { staticClass: "table" },
      [
        _c(
          "TransitionFade",
          [
            _vm.items
              ? _c("BaseTable", {
                  attrs: { headers: _vm.headers, body: _vm.tableReports },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.items, function(report, i) {
                        return {
                          key: "actions-" + i,
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { key: i, staticClass: "table-actions" },
                                [
                                  _c(
                                    "BaseButton",
                                    {
                                      attrs: { size: "s" },
                                      on: {
                                        click: function($event) {
                                          return _vm.showResult(report.id)
                                        }
                                      }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("BUTTON.SEE"))
                                        )
                                      ]),
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          size: "xs",
                                          icon: ["fas", "eye"]
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      })
                    ],
                    null,
                    true
                  )
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }