<template>
  <div class="ContainerReport">
    <div class="table">
      <TransitionFade>
        <BaseTable
          v-if="items"
          :headers="headers"
          :body="tableReports"
        >
          <template
            v-for="(report, i) in items"
            v-slot:[`actions-${i}`]
          >
            <div
              :key="i"
              class="table-actions"
            >
              <BaseButton
                size="s"
                @click="showResult(report.id)"
              >
                <span> {{ $t('BUTTON.SEE') }}</span>
                <font-awesome-icon
                  size="xs"
                  :icon="['fas', 'eye']"
                />
              </BaseButton>
            </div>
          </template>
        </BaseTable>
      </TransitionFade>
    </div>
  </div>
</template>

<script>
import TransitionFade from '@/transitions/TransitionFade';

export default {
  components: {
    TransitionFade,
  },

  props: {
    items: {
      type: Array,
      default: undefined,
    },
  },

  data() {
    return {
      headers: [
        [this.$t('TABLE.TITLE.PROGRAM'), this.$t('TABLE.TITLE.FINISH'), this.$t('TABLE.TITLE.ACTIONS')],
      ],
    };
  },

  computed: {
    tableReports() {
      return this.items?.map(report => ({
        program: report.nameProgram.replace('_', ' '),
        finish: report.finish,
      }));
    },
  },

  methods: {
    showResult(id) {
      const reportId = id;
      window.scrollTo(0, 0);
      this.$router.push({ name: 'Report', params: { reportId } });
    },
  },

};
</script>

<style lang="scss" scoped>
.ContainerReport {
  background: #fff;
  //padding: $spacing-xl;
  box-shadow: $shadow-elevation-1;

  .table:empty {
    @include empty-content(150px);
  }

  .table-actions {
    display: grid;
    justify-content: end;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    gap: $spacing-s;

    .BaseButton {
      min-width: 30px;
    }
  }
}
</style>
